/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Buffer } from 'buffer';
import axios from 'axios';
import './App.scss';
import Clock from 'react-live-clock';
import { check } from 'prettier';
import { useNavigate } from 'react-router-dom';
import Logo from './Assets/logo.png';
import LogoNicchio from './Assets/logoNicchio.png';
import ClockIcon from './Assets/Clock.png';
import Loading from './Assets/Loading.gif';
import { getXmlData } from './xmlToString';

let warning;

let usuario;
let senha;
async function Login(cpf, password) {
  const encodedAuth = Buffer.from(`${cpf}:${password}`).toString('base64');

  try {
    const { data } = await axios.get(
      'https://mighty-hamlet-12420.herokuapp.com/http://209.145.50.47:10004/login',
      {
        headers: {
          Authorization: `Basic ${encodedAuth}=`
        }
      }
    );
  } catch (error) {
    warning = true;
    console.log(data);
    throw new Error(`${error.response.data} login`);
  }
  usuario = cpf;
  senha = password;
  return true;
}

let tableContent;
const rowPerPage = 14;
let tableLimit = 0;
function getTabela() {
  console.log(usuario);
  const data = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ser="http://services.senior.com.br">\r\n   <soapenv:Header/>\r\n   <soapenv:Body>\r\n      <ser:GetMovimento>\r\n         <user>portalnew</user>\r\n         <password>portalnew</password>\r\n         <encryption>0</encryption>\r\n         <parameters>\r\n            <arqJso>{"Dados":{"codemp":"1","tipoProcesso":"acaoPainelVIX","tipoAcao":"C"}}</arqJso>\r\n            <texJso>acaoPainelVIX</texJso>\r\n         </parameters>\r\n      </ser:GetMovimento>\r\n   </soapenv:Body>\r\n</soapenv:Envelope>`;

  const tableRows = {
    method: 'post',
    url: 'https://mighty-hamlet-12420.herokuapp.com/http://sapiens.nicchio.com.br:8080/g5-senior-services/sapiens_Synccom_senior_g5_co_int_custom_new',
    headers: {
      'Content-Type': 'text/xml',
      'Access-Control-Allow-Origin': '*'
    },
    transformResponse: [
      data => {
        try {
          if (data.includes('<tipRet>2</tipRet>')) {
            const start = data.search('<msgRet>');
            const end = data.search('</msgRet>');
            const error = data.slice(start + 8, end);
            throw error;
          }
          if (data.includes('<erroExecucao>')) {
            const start = data.search('<erroExecucao>');
            const end = data.search('</erroExecucao>');
            const error = data.slice(start + 14, end);
            throw error;
          }
          if (data.includes('<retorno>') || data.includes('<arqJso ')) {
            return data;
          }
          if (data.includes('<arqJso>')) {
            return getXmlData(data);
          }
          return data;
        } catch (err) {
          warning = true;
          console.log(data);
          throw err;
        }
      }
    ],
    data
  };

  axios(tableRows)
    .then(function (response) {
      tableContent = response.data;
    })
    .then(function (response) {
      tableLimit =
        Math.floor(tableContent?.length / rowPerPage) +
        (tableContent?.length % rowPerPage ? 1 : 0);
    })
    .catch(function (error) {
      warning = true;
      console.log(error);
    });
}

let dolar = 0;
function getDolar() {
  const dolarValue = {
    method: 'get',
    url: 'https://economia.awesomeapi.com.br/last/USD-BRL',
    headers: {}
  };

  axios(dolarValue)
    .then(function (response) {
      dolar = response.data.USDBRL.low;
    })
    .catch(function (error) {
      warning = true;
      console.log(error);
    });
}

function App() {
  const [login, setLogin] = useState(false);

  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1}`;

  const [counter, setCounter] = useState(240);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [pageTimer, setPageTimer] = useState(60);
  const [showPage, setShowPage] = useState(0);

  const [flip, setFlip] = useState(true);

  const staticJson = {
    Dados: [
      {
        nv: 'NV/20221',
        origem: 'COLATINA',
        porto: 'VITÓRIA',
        navio: 'Royal Caribbean',
        data: '18/07/2022',
        status: 'Confirmado',
        fstatus: 0
      }
    ]
  };

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      setCounter(240);
      setFlip(!flip);

      getDolar();
      getTabela();
    }
    setMinutes(Math.trunc(counter / 60));
    setSeconds(counter - minutes * 60);
  }, [counter, minutes]);

  useEffect(() => {
    pageTimer > 0 && setTimeout(() => setPageTimer(pageTimer - 1), 1000);
    if (pageTimer === 0) {
      setPageTimer(60);
      if (showPage < tableLimit - 1) {
        console.log(tableLimit);
        setShowPage(showPage + 1);
      } else {
        setShowPage(0);
      }
    }
  }, [pageTimer]);

  useEffect(() => {
    getDolar();
    getTabela();
  }, []);

  const [cpfInput, setCpfInput] = useState('');
  const [passInput, setPassInput] = useState('');

  const navigate = useNavigate();

  return (
    <div className="Background">
      {warning && (
        <div className="fade">
          <div className="popup">
            <div className="imgWrapper">
              <img src={Loading} alt="Houve um problema ao receber os dados" />
            </div>
            <p>
              {' '}
              Aguardando dados
              <span>.</span>
              <span className="f">.</span>
              <span className="s">.</span>
            </p>
          </div>
        </div>
      )}
      <div className="Decal">
        {login ? (
          <div className="Wave">
            <div className="Header">
              <a
                onClick={() => {
                  navigate('/');
                }}
              >
                <img
                  src={Logo}
                  alt="Nicchio Café S.A. Exportação e importação"
                />
              </a>

              <div className="dolar">
                <p>
                  Cotação do dolar
                  <span>
                    {date}
                    <Clock
                      format="HH:mm"
                      ticking
                      timezone="America/Sao_Paulo"
                    />
                    -currconv
                  </span>
                </p>
                <div>
                  <span>
                    <p> 1 </p>
                  </span>
                  <span>
                    <p> Dolar Americano </p>
                  </span>
                </div>
                <div>
                  <span>
                    <p> {parseFloat(dolar).toFixed(2)} </p>
                  </span>
                  <span>
                    <p> Real Brasileiro </p>
                  </span>
                </div>
              </div>

              <div className="timers">
                <span className="hour">
                  <img src={ClockIcon} alt="São Paulo, SP GMT-3" />
                  <span>
                    <p className="large">
                      <Clock
                        format="HH:mm"
                        ticking
                        timezone="America/Sao_Paulo"
                      />
                    </p>
                    <p className="small"> São Paulo, SP GMT-3 </p>
                  </span>
                </span>

                <span className="att">
                  <p className="small"> Próxima atualização </p>
                  <p className="large">
                    {minutes}:{seconds.toString().padStart(2, '0')}
                  </p>
                </span>
              </div>
            </div>

            <table className="Table">
              <tbody className={flip ? `flipA` : `flipB`}>
                <tr>
                  <th> NV </th>
                  <th> Origem </th>
                  <th> Porto/Front </th>
                  <th> Destino </th>
                  <th> Navio </th>
                  <th> Data/Hora </th>
                  <th> Status </th>
                </tr>

                {/* tableContent?.slice(0, 14).map((row, id) => {
                  return (
                    <tr key={`${id}`}>
                      <td style={{ animationDelay: `${(id + 0) / 2.5}s` }}>
                        {row.nv}
                      </td>
                      <td style={{ animationDelay: `${(id + 1) / 2.5}s` }}>
                        {row.origem}
                      </td>
                      <td style={{ animationDelay: `${(id + 2) / 2.5}s` }}>
                        {row.porto}
                      </td>
                      <td style={{ animationDelay: `${(id + 2) / 2.5}s` }}>
                        {row.portodestino}
                      </td>
                      <td style={{ animationDelay: `${(id + 3) / 2.5}s` }}>
                        {row.navio}
                      </td>
                      <td style={{ animationDelay: `${(id + 4) / 2.5}s` }}>
                        {row.data}
                      </td>
                      <td
                        className={`color${row.fstatus}`}
                        style={{ animationDelay: `${(id + 5) / 2.5}s` }}
                      >
                        {row.status}
                      </td>
                    </tr>
                  );
                }) */}

                {[...Array(tableLimit)].map((table, i) => {
                  return tableContent
                    ?.slice(
                      (i + 1) * rowPerPage - rowPerPage,
                      (i + 1) * rowPerPage
                    )
                    .map((row, id) => {
                      if (row.fstatup === 1)
                        return (
                          <tr
                            className={showPage === i ? 'show' : 'hide'}
                            key={`${id}`}
                          >
                            <td
                              style={{ animationDelay: `${(id + 0) / 2.5}s` }}
                            >
                              {row.nv}
                            </td>
                            <td
                              style={{ animationDelay: `${(id + 1) / 2.5}s` }}
                            >
                              {row.origem}
                            </td>
                            <td
                              style={{ animationDelay: `${(id + 2) / 2.5}s` }}
                            >
                              {row.porto}
                            </td>
                            <td
                              style={{ animationDelay: `${(id + 2) / 2.5}s` }}
                            >
                              {row.portodestino}
                            </td>
                            <td
                              style={{ animationDelay: `${(id + 3) / 2.5}s` }}
                            >
                              {row.navio}
                            </td>
                            <td
                              style={{ animationDelay: `${(id + 4) / 2.5}s` }}
                            >
                              {row.data}
                            </td>
                            <td
                              className={`color${row.fstatus}`}
                              style={{ animationDelay: `${(id + 5) / 2.5}s` }}
                            >
                              {row.status}
                            </td>
                          </tr>
                        );
                    });
                })}
              </tbody>
            </table>

            <div className="pagination">
              {[...Array(tableLimit)].map((table, i) => {
                return (
                  <span
                    key={i}
                    id={i}
                    className={showPage === i ? 'show' : 'hide'}
                  />
                );
              })}
            </div>
          </div>
        ) : (
          <div className="loginWrapper">
            <img
              src={LogoNicchio}
              alt="Nicchio Café S.A. Exportação e importação"
            />
            <h1> Login </h1>
            <input
              type="text"
              id="cpf"
              placeholder="Cpf"
              onChange={v => {
                setCpfInput(v.target.value);
              }}
            />
            <input
              type="password"
              id="senha"
              placeholder="Senha"
              onChange={v => {
                setPassInput(v.target.value);
              }}
            />
            <button
              type="submit"
              value="Submit"
              onClick={async () => {
                const checkLogin = await Login(cpfInput, passInput);
                setLogin(checkLogin);
                getTabela();
              }}
            >
              entrar
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
